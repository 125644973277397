import React from 'react';
import { Redirect } from 'react-router-dom';
import { USER_TYPES } from '../constants';

export default function AuthRedirect(pathname, authProps) {
  const { isLoading, isSoftAuthenticated, isFullyAuthenticated, twoFactorAuthSetupRequired, userType } = authProps;
  pathname = pathname.toLowerCase();

  if (!isLoading) {
    // Login
    if (pathname === '/auth/login' || pathname.includes('/auth/create-account') || pathname.includes('/auth/forgot-password')) {
      if (isSoftAuthenticated) {
        if (!isFullyAuthenticated) return <Redirect to='/auth/2fa' />;
        else return HomeRedirect(authProps);
      }
    }

    // Two Factor Setup or Authentication
    else if (pathname.includes('/auth/2fa')) {
      if (!isSoftAuthenticated) return <Redirect to='/auth/login' />;
      else if (isFullyAuthenticated) return <Redirect to='/' />;
      else if (pathname !== '/auth/2fa') <Redirect to='/auth/2fa' />;
    }

    else if (pathname.includes('/admin/') && userType !== USER_TYPES.ADMIN) return HomeRedirect(authProps);

    // Guest users should only be able to access /, /history/*, and /account
    else if (userType === USER_TYPES.GUEST && pathname !== '/' && !pathname.startsWith('/history') && !pathname.startsWith('/account')) return HomeRedirect(authProps);

    // Any other non-auth page
    else if (!isSoftAuthenticated || !isFullyAuthenticated || twoFactorAuthSetupRequired) return <Redirect to='/auth/login' />;

    else if (pathname === '/') return HomeRedirect(authProps);
  }
  return null;
}

// This function assumes loading is complete and the user is fully authenticated
function HomeRedirect(authProps) {
  let { userType, hasBusiness, skeletonUserUserType, skeletonUserHasBusiness } = authProps;

  if (skeletonUserUserType) {
    userType = skeletonUserUserType;
    hasBusiness = skeletonUserHasBusiness;
  }

  if ((userType === USER_TYPES.ADMIN && hasBusiness) || userType === USER_TYPES.USER) return <Redirect to='/satellite' />;
  else if (userType === USER_TYPES.ADMIN && !hasBusiness) return <Redirect to='/admin' />;
  else return <Redirect to='/history/guest' />;
}

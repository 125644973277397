import { FishCatch, TripPosition, TripSet } from './';
import { DateTime } from 'luxon';

export const HAUL = 'haul';

export default class TripHaul {
  static thaw(tripHaulObj) {
    if (tripHaulObj == null) return null;
    const tripHaul = new TripHaul();
    if (tripHaulObj.id != null) tripHaul.setID(tripHaulObj.id);
    if (tripHaulObj.startTimestamp != null) tripHaul.setStartTimestamp(DateTime.fromISO(tripHaulObj.startTimestamp));
    if (tripHaulObj.stopTimestamp != null) tripHaul.setStopTimestamp(DateTime.fromISO(tripHaulObj.stopTimestamp));
    if (tripHaulObj.isAnalyzed != null) tripHaul.setIsAnalyzed(tripHaulObj.isAnalyzed == null ? null : (tripHaulObj.isAnalyzed ? true : false));
    if (tripHaulObj.elapsedTime != null) tripHaul.setElapsedTime(tripHaulObj.elapsedTime);
    if (tripHaulObj.crewAmount != null) tripHaul.setCrewAmount(tripHaulObj.crewAmount);
    if (tripHaulObj.estTarget != null) tripHaul.setEstTarget(tripHaulObj.estTarget);
    if (tripHaulObj.estBycatch != null) tripHaul.setEstBycatch(tripHaulObj.estBycatch);
    if (tripHaulObj.laborHours != null) tripHaul.setLaborHours(tripHaulObj.laborHours);
    if (tripHaulObj.retainedLbs != null) tripHaul.setRetainedLbs(tripHaulObj.retainedLbs);
    if (tripHaulObj.discardLbs != null) tripHaul.setDiscardLbs(tripHaulObj.discardLbs);
    if (tripHaulObj.catchLbsPerProcessingHour != null) tripHaul.setCatchLbsPerProcessingHour(tripHaulObj.catchLbsPerProcessingHour);
    if (tripHaulObj.catchLbsPerDragMinute != null) tripHaul.setCatchLbsPerDragMinute(tripHaulObj.catchLbsPerDragMinute);
    if (tripHaulObj.catchLbsPerGear != null) tripHaul.setCatchLbsPerGear(tripHaulObj.catchLbsPerGear);
    if (tripHaulObj.totalDistance != null) tripHaul.setTotalDistance(tripHaulObj.totalDistance);
    if (tripHaulObj.averageSpeed != null) tripHaul.setAverageSpeed(tripHaulObj.averageSpeed);
    if (tripHaulObj.tripSetId != null) tripHaul.setTripSetID(tripHaulObj.tripSetId);
    if (tripHaulObj.retainedLbsPerDragMinute != null) tripHaul.setRetainedLbsPerDragMinute(tripHaulObj.retainedLbsPerDragMinute);
    if (tripHaulObj.discardLbsPerDragMinute != null) tripHaul.setDiscardLbsPerDragMinute(tripHaulObj.discardLbsPerDragMinute);
    if (tripHaulObj.retainedLbsPerGear != null) tripHaul.setRetainedLbsPerGear(tripHaulObj.retainedLbsPerGear);
    if (tripHaulObj.discardLbsPerGear != null) tripHaul.setDiscardLbsPerGear(tripHaulObj.discardLbsPerGear);
    if (tripHaulObj.gearSubtype != null) tripHaul.setGearSubtype(tripHaulObj.gearSubtype);
    if (tripHaulObj.catchQuality != null) tripHaul.setCatchQuality(tripHaulObj.catchQuality);
    if (tripHaulObj.catchQualityNP != null) tripHaul.setCatchQualityNP(tripHaulObj.catchQualityNP);
    if (tripHaulObj.catch != null) tripHaul.setCatch(FishCatch.thawList(tripHaulObj.catch));
    if (tripHaulObj.positions != null) tripHaul.setPositions(TripPosition.thawList(tripHaulObj.positions));
    if (tripHaulObj.set != null) tripHaul.setSet(TripSet.thaw(tripHaulObj.set));
    if (tripHaulObj.excludeFromCalculations != null) tripHaul.setExcludeFromCalculations(tripHaulObj.excludeFromCalculations == null ? null : (tripHaulObj.excludeFromCalculations ? true : false));
    return tripHaul;
  }

  static thawList(tripHaulObjList) {
    if (tripHaulObjList == null) return [];
    var tripHauls = [];
    for (var i = 0; i < tripHaulObjList.length; i++) {
      tripHauls.push(TripHaul.thaw(tripHaulObjList[i]));
    }
    return tripHauls;
  }

  static freeze(tripHaul) {
    var tripHaulObj = {};
    if (tripHaul.getID() != null) tripHaulObj.id = tripHaul.getID();
    if (tripHaul.getStartTimestamp() != null) tripHaulObj.startTimestamp = tripHaul.getStartTimestamp();
    if (tripHaul.getStopTimestamp() != null) tripHaulObj.stopTimestamp = tripHaul.getStopTimestamp();
    if (tripHaul.getIsAnalyzed() != null) tripHaulObj.isAnalyzed = tripHaul.getIsAnalyzed() == null ? null : (tripHaul.getIsAnalyzed() ? 1 : 0);
    if (tripHaul.getElapsedTime() != null) tripHaulObj.elapsedTime = tripHaul.getElapsedTime();
    if (tripHaul.getCrewAmount() != null) tripHaulObj.crewAmount = tripHaul.getCrewAmount();
    if (tripHaul.getEstTarget() != null) tripHaulObj.estTarget = tripHaul.getEstTarget();
    if (tripHaul.getEstBycatch() != null) tripHaulObj.estBycatch = tripHaul.getEstBycatch();
    if (tripHaul.getLaborHours() != null) tripHaulObj.laborHours = tripHaul.getLaborHours();
    if (tripHaul.getRetainedLbs() != null) tripHaulObj.retainedLbs = tripHaul.getRetainedLbs();
    if (tripHaul.getDiscardLbs() != null) tripHaulObj.discardLbs = tripHaul.getDiscardLbs();
    if (tripHaul.getCatchLbsPerProcessingHour() != null) tripHaulObj.catchLbsPerProcessingHour = tripHaul.getCatchLbsPerProcessingHour();
    if (tripHaul.getCatchLbsPerDragMinute() != null) tripHaulObj.catchLbsPerDragMinute = tripHaul.getCatchLbsPerDragMinute();
    if (tripHaul.getCatchLbsPerGear() != null) tripHaulObj.catchLbsPerGear = tripHaul.getCatchLbsPerGear();
    if (tripHaul.getTotalDistance() != null) tripHaulObj.totalDistance = tripHaul.getTotalDistance();
    if (tripHaul.getAverageSpeed() != null) tripHaulObj.averageSpeed = tripHaul.getAverageSpeed();
    if (tripHaul.getTripSetID() != null) tripHaulObj.tripSetId = tripHaul.getTripSetID();
    if (tripHaul.getRetainedLbsPerDragMinute() != null) tripHaulObj.retainedLbsPerDragMinute = tripHaul.getRetainedLbsPerDragMinute();
    if (tripHaul.getDiscardLbsPerDragMinute() != null) tripHaulObj.discardLbsPerDragMinute = tripHaul.getDiscardLbsPerDragMinute();
    if (tripHaul.getRetainedLbsPerGear() != null) tripHaulObj.retainedLbsPerGear = tripHaul.getRetainedLbsPerGear();
    if (tripHaul.getDiscardLbsPerGear() != null) tripHaulObj.discardLbsPerGear = tripHaul.getDiscardLbsPerGear();
    if (tripHaul.getGearSubtype() != null) tripHaulObj.gearSubtype = tripHaul.getGearSubtype();
    if (tripHaul.getCatchQuality() != null) tripHaulObj.catchQuality = tripHaul.getCatchQuality();
    if (tripHaul.getCatchQualityNP() != null) tripHaulObj.catchQualityNP = tripHaul.getCatchQualityNP();
    if (tripHaul.getCatch() != null) tripHaulObj.catch = FishCatch.freezeList(tripHaul.getCatch());
    if (tripHaul.getSet() != null) tripHaulObj.set = TripSet.freeze(tripHaul.getSet());
    if (tripHaul.getExcludeFromCalculations() != null) tripHaulObj.excludeFromCalculations = tripHaul.getExcludeFromCalculations() == null ? null : (tripHaul.getExcludeFromCalculations() ? 1 : 0);
    return tripHaulObj;
  }

  static freezeList(tripHauls) {
    if (tripHauls == null) return [];
    var tripHaulObjList = [];
    for (var i = 0; i < tripHauls.length; i++) {
      tripHaulObjList.push(TripHaul.freeze(tripHauls[i]));
    }
    return tripHaulObjList;
  }

  constructor() {
    this.setID(null);
    this.setStartTimestamp(null);
    this.setStopTimestamp(null);
    this.setIsAnalyzed(null);
    this.setElapsedTime(null);
    this.setCrewAmount(null);
    this.setEstTarget(null);
    this.setEstBycatch(null);
    this.setLaborHours(null);
    this.setRetainedLbs(null);
    this.setDiscardLbs(null);
    this.setCatchLbsPerProcessingHour(null);
    this.setCatchLbsPerDragMinute(null);
    this.setCatchLbsPerGear(null);
    this.setTotalDistance(null);
    this.setAverageSpeed(null);
    this.setTripSetID(null);
    this.setCatch(null);
    this.setPositions(null);
    this.setRetainedLbsPerDragMinute(null);
    this.setDiscardLbsPerDragMinute(null);
    this.setRetainedLbsPerGear(null);
    this.setDiscardLbsPerGear(null);
    this.setGearSubtype(null);
    this.setCatchQuality(null);
    this.setCatchQualityNP(null);
    this.setSet(null);
    this.setExcludeFromCalculations(null);
  }

  /* -------------------------
     -------------------------
     -------- SETTERS --------
     -------------------------
     ------------------------- */

  setID(id) {
    this.id = id;
  }

  setStartTimestamp(startTimestamp) {
    this.startTimestamp = startTimestamp;
  }

  setStopTimestamp(stopTimestamp) {
    this.stopTimestamp = stopTimestamp;
  }

  setIsAnalyzed(isAnalyzed) {
    this.isAnalyzed = isAnalyzed;
  }

  setElapsedTime(elapsedTime) {
    this.elapsedTime = elapsedTime;
  }

  setCrewAmount(crewAmount) {
    this.crewAmount = crewAmount;
  }

  setEstTarget(estTarget) {
    this.estTarget = estTarget;
  }

  setEstBycatch(estBycatch) {
    this.estBycatch = estBycatch;
  }

  setLaborHours(laborHours) {
    this.laborHours = laborHours;
  }

  setRetainedLbs(retainedLbs) {
    this.retainedLbs = retainedLbs;
  }

  setDiscardLbs(discardLbs) {
    this.discardLbs = discardLbs;
  }

  setCatchLbsPerProcessingHour(catchLbsPerProcessingHour) {
    this.catchLbsPerProcessingHour = catchLbsPerProcessingHour;
  }

  setCatchLbsPerDragMinute(catchLbsPerDragMinute) {
    this.catchLbsPerDragMinute = catchLbsPerDragMinute;
  }

  setCatchLbsPerGear(catchLbsPerGear) {
    this.catchLbsPerGear = catchLbsPerGear;
  }

  setTotalDistance(totalDistance) {
    this.totalDistance = totalDistance;
  }

  setAverageSpeed(averageSpeed) {
    this.averageSpeed = averageSpeed;
  }

  setTripSetID(tripSetID) {
    this.tripSetID = tripSetID;
  }

  setCatch(catchValue) {
    this.catch = catchValue;
  }

  setPositions(positions) {
    this.positions = positions;
  }

  setRetainedLbsPerDragMinute(retainedLbsPerDragMinute) {
    this.retainedLbsPerDragMinute = retainedLbsPerDragMinute;
  }

  setDiscardLbsPerDragMinute(discardLbsPerDragMinute) {
    this.discardLbsPerDragMinute = discardLbsPerDragMinute;
  }

  setRetainedLbsPerGear(retainedLbsPerGear) {
    this.retainedLbsPerGear = retainedLbsPerGear;
  }

  setDiscardLbsPerGear(discardLbsPerGear) {
    this.discardLbsPerGear = discardLbsPerGear;
  }

  setGearSubtype(gearSubtype) {
    this.gearSubtype = gearSubtype;
  }

  setCatchQuality(catchQuality) {
    this.catchQuality = catchQuality;
  }

  setCatchQualityNP(catchQualityNP) {
    this.catchQualityNP = catchQualityNP;
  }

  setSet(set) {
    this.set = set;
  }

  setExcludeFromCalculations(excludeFromCalculations) {
    this.excludeFromCalculations = excludeFromCalculations;
  }

  /* -------------------------
     -------------------------
     -------- GETTERS --------
     -------------------------
     ------------------------- */

  getID() {
    return this.id;
  }

  getStartTimestamp() {
    return this.startTimestamp;
  }

  getStopTimestamp() {
    return this.stopTimestamp;
  }

  getIsAnalyzed() {
    return this.isAnalyzed;
  }

  getElapsedTime() {
    return this.elapsedTime;
  }

  getCrewAmount() {
    return this.crewAmount;
  }

  getEstTarget() {
    return this.estTarget;
  }

  getEstBycatch() {
    return this.estBycatch;
  }

  getLaborHours() {
    return this.laborHours;
  }

  getRetainedLbs() {
    return this.retainedLbs;
  }

  getDiscardLbs() {
    return this.discardLbs;
  }

  getCatchLbsPerProcessingHour() {
    return this.catchLbsPerProcessingHour;
  }

  getCatchLbsPerDragMinute() {
    return this.catchLbsPerDragMinute;
  }

  getCatchLbsPerGear() {
    return this.catchLbsPerGear;
  }

  getTotalDistance() {
    return this.totalDistance;
  }

  getAverageSpeed() {
    return this.averageSpeed;
  }

  getTripSetID() {
    return this.tripSetID;
  }

  getCatch() {
    return this.catch;
  }

  getPositions() {
    return this.positions;
  }

  getRetainedLbsPerDragMinute() {
    return this.retainedLbsPerDragMinute;
  }

  getDiscardLbsPerDragMinute() {
    return this.discardLbsPerDragMinute;
  }

  getRetainedLbsPerGear() {
    return this.retainedLbsPerGear;
  }

  getDiscardLbsPerGear() {
    return this.discardLbsPerGear;
  }

  getGearSubtype() {
    return this.gearSubtype;
  }

  getCatchQuality() {
    return this.catchQuality;
  }

  getCatchQualityNP() {
    return this.catchQualityNP;
  }

  getSet() {
    return this.set;
  }

  getExcludeFromCalculations() {
    return this.excludeFromCalculations;
  }

  /* -------------------------
     -------------------------
     -------- HELPERS --------
     -------------------------
     ------------------------- */

  listGoogleMapsPositions() {
    return TripPosition.listGoogleMapsPositions(this.positions);
  }

  getObjectType() {
    return HAUL;
  }
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress, TextField, Typography, Autocomplete, FormControlLabel, Checkbox } from '@mui/material';

// Alerts
import { clearErrors, setError } from '../alerts';

// API
import { listUsers, listGuestInvitations, getTrip, listGuestTripPermissionsForTrip } from '../api/admin';
import { LineItem } from '@lexcelon/react-util';
import { DATE_TIME_ZONE_FORMAT, USER_TYPES } from '../constants';

const SHAREABLE_ITEMS = [
  { label: 'Fishing Performance', key: 'fishingPerformance', showAnalyzedSets: true, showAnalyzedHauls: true, children: [
    { label: 'Base Trip Summary', key: 'baseTripSummary' },
    { label: 'Steam GPS Track', key: 'steamGpsTrack' },
    { label: 'Steam Data', key: 'steamData' },
    { label: 'Non-Analyzed Hauls', key: 'nonAnalyzedHauls' },
    { label: 'Non-Analyzed Sets', key: 'nonAnalyzedSets' },
  ] },
  { label: 'Compliance Reports', key: 'complianceReports' },
  { label: 'Videos', key: 'videos' },
  { label: 'Images', key: 'images' }
];

// Flatten SHAREABLE_ITEMS
const FLATTENED_SHAREABLE_ITEMS = [];
SHAREABLE_ITEMS.forEach(item => {
  FLATTENED_SHAREABLE_ITEMS.push(item);
  item.children?.forEach(child => FLATTENED_SHAREABLE_ITEMS.push(child));
});

class GuestTripPermissionForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      trip: null,
      guestUsers: [],
      guestUserInvitations: [],
      userSelection: null,
      isLoadingGuestUsers: true,
      isLoadingGuestUserInvitations: true,
      redirectToTripPermissions: false,
      ...(Object.fromEntries(FLATTENED_SHAREABLE_ITEMS.map(item => [item.key, false]))),
      analyzedSets: [],
      analyzedHauls: [],
      isLoading: true
    };
  }

  componentDidMount() {
    const { guestTripPermission } = this.props;
    // If creating a new guest trip permission, retrieve guest users and guest user invitations
    if (guestTripPermission == null) {
      this.setState({ isLoadingGuestUsers: true, isLoadingGuestUserInvitations: true });
      listGuestTripPermissionsForTrip(this.props.tripId).then(guestTripPermissions => {
        listUsers().then(users => {
          const guestUsers = users.filter(user => user.getUserType() === USER_TYPES.GUEST);
          this.setState({
            guestUsers: guestUsers.filter(guestUser => guestTripPermissions.find(guestTripPermission => guestUser.getID() === guestTripPermission.getGuestID()) == null),
            isLoadingGuestUsers: false
          });
        }).catch(error => {
          setError(error ? error : 'Error: Unable to retrieve guest users.');
          this.setState({ isLoadingGuestUsers: false });
        });

        listGuestInvitations().then(guestUserInvitations => {
          this.setState({
            guestUserInvitations: guestUserInvitations.filter(guestUserInvitation => guestTripPermissions.find(guestTripPermission => guestUserInvitation.getID() === guestTripPermission.getGuestInvitationID()) == null),
            isLoadingGuestUserInvitations: false
          });
        }).catch(error => {
          setError(error ? error : 'Error: Unable to retrieve guest user invitations.');
          this.setState({ isLoadingGuestUserInvitations: false });
        });
      }).catch(error => {
        setError(error ? error : 'Error: Unable to retrieve guest trip permissions.');
        this.setState({ isLoadingGuestUsers: false, isLoadingGuestUserInvitations: false });
      });
    }

    // Retrieve trip and analyzed sets/hauls information
    const guestTripSetHaulPermissions = guestTripPermission?.getGuestTripSetHaulPermissions() ?? [];
    getTrip(this.props.tripId).then(trip => {
      const analyzedSets = [];
      const analyzedHauls = [];
      trip.getSets()?.forEach(set => {
        if (set.getIsAnalyzed()) {
          const setPermission = guestTripSetHaulPermissions.find(guestTripSetHaulPermission => guestTripSetHaulPermission.getSetID() === set.getID());
          analyzedSets.push({
            set,
            isSelected: setPermission != null,
            secondarySetData: setPermission?.getSecondarySetData() ?? true
          });
        }

        if (set.getHaul()?.getIsAnalyzed()) {
          const haulPermission = guestTripSetHaulPermissions.find(guestTripSetHaulPermission => guestTripSetHaulPermission.getHaulID() === set.getHaul()?.getID());
          analyzedHauls.push({
            haul: set.getHaul(),
            isSelected: haulPermission != null,
            secondaryHaulData: haulPermission?.getSecondaryHaulData() ?? true
          });
        }
      });
      this.setState({ trip, analyzedSets, analyzedHauls, isLoading: false }, () => {
        // Update state with guest user information if editing an existing guest trip permission
        if (guestTripPermission != null) {
          if (guestTripPermission.getFishingPerformance()) this.setState({ fishingPerformance: true });
          if (guestTripPermission.getBaseTripSummary()) this.setState({ baseTripSummary: true });
          if (guestTripPermission.getSteamGpsTrack()) this.setState({ steamGpsTrack: true });
          if (guestTripPermission.getSteamData()) this.setState({ steamData: true });
          if (guestTripPermission.getNonAnalyzedHauls()) this.setState({ nonAnalyzedHauls: true });
          if (guestTripPermission.getNonAnalyzedSets()) this.setState({ nonAnalyzedSets: true });
          if (guestTripPermission.getComplianceReports()) this.setState({ complianceReports: true });
          if (guestTripPermission.getVideos()) this.setState({ videos: true });
          if (guestTripPermission.getImages()) this.setState({ images: true });
        }
      });
    }).catch(error => {
      this.setState({ isLoading: false });
      setError(error ? error : 'Error: Unable to retrieve trip.');
    });
  }

  componentWillUnmount() {
    clearErrors();
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();

    const { trip, userSelection } = this.state;

    if (this.props.guestTripPermission == null && userSelection.id == null) {
      setError('You must select a guest user.');
      return;
    }

    // Ensure at least one permission was selected
    const permissionMap = Object.fromEntries(FLATTENED_SHAREABLE_ITEMS.map(item => [item.key, this.state[item.key]]));
    if (!Object.values(permissionMap).some(permission => permission)) {
      setError('You must select at least one permission.');
      return;
    }

    // Add key/value pairs from FLATTENED_SHAREABLE_ITEMS
    if (this.props.guestTripPermission == null) {
      permissionMap[userSelection.type === 'user' ? 'guestId' : 'guestInvitationId'] = userSelection.id;
      permissionMap.tripId = trip.getID();
    }

    // Add hauls and sets to permissionObj
    const guestTripSetHaulPermissions = [];
    this.state.analyzedSets.forEach(({ set, isSelected, secondarySetData }) => {
      if (isSelected) guestTripSetHaulPermissions.push({ setId: set.getID(), secondarySetData });
    });
    this.state.analyzedHauls.forEach(({ haul, isSelected, secondaryHaulData }) => {
      if (isSelected) guestTripSetHaulPermissions.push({ haulId: haul.getID(), secondaryHaulData });
    });

    permissionMap.guestTripSetHaulPermissions = guestTripSetHaulPermissions;
    this.props.onSubmit(permissionMap);
  }

  render() {
    if (this.state.isLoading) return <CircularProgress style={{ marginLeft: '50%', marginTop: '20%' }} />;

    const userOptions = [];
    for (const guestUser of this.state.guestUsers) {
      userOptions.push({ label: `${guestUser.getName()} (${guestUser.getEmail()})`, type: 'user', id: guestUser.getID(), isInternal: guestUser.getAssociatedWithBusinessID() != null && guestUser.getAssociatedWithBusinessID() === this.state.trip?.getBoat()?.getBusinessID() });
    }
    for (const guestUserInvitation of this.state.guestUserInvitations) {
      userOptions.push({ label: `${guestUserInvitation.getEmail()} (Invited)`, type: 'invitation', id: guestUserInvitation.getID(), isInternal: guestUserInvitation.getAssociatedWithBusinessID() != null && guestUserInvitation.getAssociatedWithBusinessID() === this.state.trip?.getBoat()?.getBusinessID() });
    }
    return (
      <div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', margin: '3em' }}>
          <Typography variant='h1'>Share Trip</Typography>
          {this.state.isLoadingBusinesses && <CircularProgress style={{ marginLeft: '20px' }} />}
        </div>

        <LineItem
          description='Trip ID'
          value={this.state.trip?.getID()}
        />

        <LineItem
          description='Boat Name'
          value={this.state.trip?.getBoat()?.getName()}
        />

        <LineItem
          description='Departure Date'
          value={this.state.trip?.getStartTimestamp()?.toLocaleString()}
        />

        {this.props.guestTripPermission != null &&
        <LineItem
          description='Guest User'
          value={this.props.guestTripPermission?.getGuest()?.getName() ?? this.props.guestTripPermission?.getGuestInvitation()?.getEmail()}
        />}

        <form onSubmit={this.onSubmit}>
          {this.props.guestTripPermission == null && (
            <>
              <Autocomplete
                disablePortal
                value={this.state.userSelection}
                getOptionLabel={option => option?.label}
                options={userOptions ?? []}
                disabled={this.props.isLoading || this.state.isLoadingBusinesses}
                onChange={(_, userSelection) => this.setState({ userSelection })}
                style={{ width: '100%', marginBottom: '10px' }}
                renderInput={(params) => <TextField {...params} variant='filled' label='Guest User' />}
              />

              {this.state.userSelection != null && !this.state.userSelection.isInternal && (
                <Typography variant='body2' style={{ color: 'red' }}>This is an external user.</Typography>
              )}
            </>
          )}

          {SHAREABLE_ITEMS.map(item => (
            <div key={item.key}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state[item.key]}
                    onChange={(val) => this.setState({ [item.key]: val.target.checked })}
                  />
                }
                label={item.label}
              />

              {this.state[item.key] && item.children?.map(child => (
                <div key={child.key} style={{ marginLeft: 20 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state[child.key]}
                        onChange={(val) => this.setState({ [child.key]: val.target.checked })}
                      />
                    }
                    label={child.label}
                  />
                </div>
              ))}

              {this.state[item.key] && item.showAnalyzedSets && (
                <div>
                  <Typography variant='body1' style={{ marginLeft: 20, fontWeight: 'bold' }}>Analyzed Sets</Typography>
                  {this.state.analyzedSets.map(({ set, isSelected, secondarySetData }) => (
                    <div key={set.getID()} style={{ marginLeft: 30 }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isSelected}
                            onChange={(val) => this.setState({ analyzedSets: this.state.analyzedSets.map(analyzedSet => analyzedSet.set.getID() === set.getID() ? { ...analyzedSet, isSelected: val.target.checked } : analyzedSet) })}
                          />
                        }
                        label={`Set (ID ${set.getID()}): ${set.getStartTimestamp()?.setZone(this.state.trip?.getTimezone() ?? 'local')?.toLocaleString(DATE_TIME_ZONE_FORMAT)}`}
                      />

                      {isSelected &&
                      <div>
                        <FormControlLabel
                          style={{ marginLeft: 20 }}
                          control={
                            <Checkbox
                              checked={secondarySetData}
                              onChange={(val) => this.setState({ analyzedSets: this.state.analyzedSets.map(analyzedSet => analyzedSet.set.getID() === set.getID() ? { ...analyzedSet, secondarySetData: val.target.checked } : analyzedSet) })}
                            />
                          }
                          label={'Secondary Set Data'}
                        />
                      </div>}
                    </div>
                  ))}
                </div>
              )}

              {this.state[item.key] && item.showAnalyzedHauls && (
                <div>
                  <Typography variant='body1' style={{ marginLeft: 20, fontWeight: 'bold' }}>Analyzed Hauls</Typography>
                  {this.state.analyzedHauls.map(({ haul, isSelected, secondaryHaulData }) => (
                    <div key={haul.getID()} style={{ marginLeft: 30 }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isSelected}
                            onChange={(val) => this.setState({ analyzedHauls: this.state.analyzedHauls.map(analyzedHaul => analyzedHaul.haul.getID() === haul.getID() ? { ...analyzedHaul, isSelected: val.target.checked } : analyzedHaul) })}
                          />
                        }
                        label={`Haul (ID ${haul.getID()}): ${haul.getStartTimestamp()?.setZone(this.state.trip?.getTimezone() ?? 'local')?.toLocaleString(DATE_TIME_ZONE_FORMAT)}`}
                      />

                      {isSelected &&
                      <div>
                        <FormControlLabel
                          style={{ marginLeft: 20 }}
                          control={
                            <Checkbox
                              checked={secondaryHaulData}
                              onChange={(val) => this.setState({ analyzedHauls: this.state.analyzedHauls.map(analyzedHaul => analyzedHaul.haul.getID() === haul.getID() ? { ...analyzedHaul, secondaryHaulData: val.target.checked } : analyzedHaul) })}
                            />
                          }
                          label={'Secondary Haul Data'}
                        />
                      </div>}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}

          <Button style={{ marginTop: 20 }} type='submit' disabled={this.props.isLoading}>
            Save Permission
            {this.props.isLoading && <CircularProgress style={{ width: 20, height: 20, marginLeft: 10, color: 'white' }} />}
          </Button>
        </form>
      </div>
    );
  }
}

GuestTripPermissionForm.propTypes = {
  tripId: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  guestTripPermission: PropTypes.object
};

export default GuestTripPermissionForm;

import axios from 'axios';

// Redux
import { store } from '../redux/store';
import { logoutUser } from '../redux/actions/authActions';

// Library
import { Boat, SatelliteMessage, Trip, TripHaul, TripSet, TripPosition, User, UserInvitation, GuestTripPermission, FishCatch, FishCatchItem } from '../lib';

// Create your axios with credentials object
const BASE_URL = process.env.REACT_APP_SERVER_URL;
const axiosWithCredentials = axios.create({
  withCredentials: true,
  baseURL: BASE_URL
});

// Add a request interceptor
axiosWithCredentials.interceptors.request.use(function (config) {
  let skeletonUserID = store?.getState()?.auth.skeletonUserID;
  if (skeletonUserID != null) {
    if (config.url?.indexOf('?') < 0) config.url += '?';
    else config.url += '&';
    config.url += `skeletonUserId=${skeletonUserID}`;
  }

  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// Intercept the success and errors, if needed
axiosWithCredentials.interceptors?.response?.use(response => {
  return Promise.resolve(response?.data || response);
}, error => {
  if (error?.response?.status === 401 && !error?.response?.config?.url?.includes('/logout')) logoutUser(store?.dispatch);
  let errorMessage = error;
  if (errorMessage.response != null) errorMessage = errorMessage.response;
  if (errorMessage.data != null) errorMessage = errorMessage.data;
  if (errorMessage.message != null) errorMessage = errorMessage.message;
  return Promise.reject(errorMessage);
});

export default axiosWithCredentials;

export function createAccount(userObj, token) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post('/auth', { ...userObj, token }).then(res => {
      resolve(User.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function login(email, password) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post('/auth/login', { email, password }).then(res => {
      resolve(User.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function logout() {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post('/user/logout').then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

export function sendMultiFactorAuthentication(mfaMethod) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post('/multi-factor/send', { mfaMethod }).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

export function verifyMultiFactorAuthentication(code) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post('/multi-factor/verify', { code }).then(res => {
      resolve(User.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function getMultiFactorUser() {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.get('/multi-factor/user').then(res => {
      resolve(User.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function updateMfaPhoneNumber(phoneNumber) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post('/multi-factor/phone-number', { phoneNumber }).then(res => {
      resolve(User.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function getUser() {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.get('/user').then(res => {
      resolve(User.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function checkInvitation(token) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.get(`/auth/check-invitation/${token}`, { token }).then(res => {
      resolve(UserInvitation.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function changePassword(currentPassword, newPassword) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post('/user/change-password', { currentPassword, newPassword }).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

export function requestChangeEmail(email) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post('/user/change-email/request', { email }).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

export function confirmChangeEmail(token) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post('/user/change-email/confirm', { token }).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

export function requestForgotPassword(email) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post('/auth/forgot-password/request', { email }).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

export function confirmForgotPassword(token, password) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post('/auth/forgot-password/confirm', { token, password }).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

export function updateUser(userObj) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.patch('/user', userObj).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

export function listBoats() {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.get('/business/boat/').then(res => {
      resolve(Boat.thawList(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function listSatelliteMessages(boatID) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.get(`/business/boat/${boatID}/satellite`).then(res => {
      resolve(SatelliteMessage.thawList(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function listTrips(boatID) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.get(`/business/boat/${boatID}/trip`).then(res => {
      resolve(Trip.thawList(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function listAllTrips() {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.get('/business/trip').then(res => {
      resolve(Trip.thawList(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function getVideoTrip(tripID) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.get(`/business/trip/${tripID}/videos`).then(res => {
      resolve(Trip.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function getTripVideoUrl(key) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.get('/business/trip/video', { params: { key: encodeURIComponent(key) } }).then(res => {
      resolve(res);
    }).catch(error => {
      reject(error);
    });
  });
}

export function getImageTrip(tripID) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.get(`/business/trip/${tripID}/images`).then(res => {
      resolve(Trip.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function getAssociatedFishCatchItemForImage(tripID, imagePath) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.get(`/business/trip/${tripID}/catch-item`, { params: { imagePath } }).then(res => {
      resolve(FishCatchItem.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function getReportTrip(tripID) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.get(`/business/trip/${tripID}/reports`).then(res => {
      resolve(Trip.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function listTripSets(tripID) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.get(`/business/trip/${tripID}/sets`).then(res => {
      resolve(TripSet.thawList(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function listTripHauls(tripID) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.get(`/business/trip/${tripID}/hauls`).then(res => {
      resolve(TripHaul.thawList(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function listTripGPS(tripID) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.get(`/business/trip/${tripID}/gps`).then(res => {
      resolve(TripPosition.thawList(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function listTripStats(tripID) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.get(`/business/trip/${tripID}/stats`).then(res => {
      resolve(Trip.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function listTripCatch(tripID) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.get(`/business/trip/${tripID}/catch`).then(res => {
      resolve(FishCatch.thawList(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function getFishCatch(fishCatchID) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.get(`/business/trip/catch/${fishCatchID}`).then(res => {
      resolve(FishCatch.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function listGuestTripPermissions() {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.get('/guest-trip-permission').then(res => {
      resolve(GuestTripPermission.thawList(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function getGuestTripPermissionFromTripID(tripID) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.get(`/guest-trip-permission/trip/${tripID}`).then(res => {
      resolve(GuestTripPermission.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}
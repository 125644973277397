import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Container } from '@mui/material';

// Alerts
import { clearErrors, setError, setSuccess } from '../../../../../alerts';

// API
import { createGuestTripPermission } from '../../../../../api/admin';
import { BackToButton, GuestTripPermissionForm } from '../../../../../components';

class ShareTrip extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      redirectToTripPermissions: false,
    };
  }

  componentWillUnmount() {
    clearErrors();
  }

  onSubmit = (permissionObj) => {
    this.setState({ isLoading: true });
    createGuestTripPermission(permissionObj).then(() => {
      setSuccess('Successfully created guest permission.');
      this.setState({ isLoading: false, redirectToTripPermissions: true });
    }).catch(error => {
      setError(error ? error : 'Error: Unable to create guest permission.');
      this.setState({ isLoading: false });
    });
  }

  render() {
    const tripId = this.props.match?.params?.id;
    return this.state.redirectToTripPermissions ? <Redirect to={`/admin/trips/${tripId}/shared`} /> : (
      <Container style={{ marginBottom: 30, marginTop: 20 }}>
        <BackToButton to={`/admin/trips/${tripId}/shared`} description='Shared Trip Data' />
        <GuestTripPermissionForm
          tripId={tripId}
          onSubmit={this.onSubmit}
          isLoading={this.state.isLoading}
        />
      </Container>
    );
  }
}

ShareTrip.propTypes = {
  match: PropTypes.object.isRequired
};

export default withRouter(ShareTrip);

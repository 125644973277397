export default class Image {
  static thaw(imageObj) {
    if (imageObj == null) return null;
    const image = new Image();
    if (imageObj.clipNumber != null) image.setClipNumber(imageObj.clipNumber);
    if (imageObj.url != null) image.setUrl(imageObj.url);
    if (imageObj.fileName != null) image.setFileName(imageObj.fileName);
    return image;
  }

  static thawList(imageObjList) {
    if (imageObjList == null) return [];
    var images = [];
    for (var i = 0; i < imageObjList.length; i++) {
      images.push(Image.thaw(imageObjList[i]));
    }
    return images;
  }

  constructor() {
    this.setClipNumber(null);
    this.setUrl(null);
    this.setFileName(null);
  }

  /* -------------------------
     -------------------------
     -------- SETTERS --------
     -------------------------
     ------------------------- */

  setClipNumber(clipNumber) {
    this.clipNumber = clipNumber;
  }

  setUrl(url) {
    this.url = url;
  }

  setFileName(fileName) {
    this.fileName = fileName;
  }

  /* -------------------------
     -------------------------
     -------- GETTERS --------
     -------------------------
     ------------------------- */

  getClipNumber() {
    return this.clipNumber;
  }

  getUrl() {
    return this.url;
  }

  getFileName() {
    return this.fileName;
  }

  /* -------------------------
     -------------------------
     -------- HELPERS --------
     -------------------------
     ------------------------- */

}

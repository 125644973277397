import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Box, Button, CircularProgress, Paper, Tab, Tabs, Typography } from '@mui/material';

// Alerts
import { setError } from '../../../../alerts';

// Components
import { BackToButton, ImageGallery, VideoGallery } from '../../../../components';
import OverviewView from './OverviewView';

// API
import { getImageTrip, getVideoTrip, getReportTrip, listTripStats, listTripCatch, getGuestTripPermissionFromTripID } from '../../../../api';

// Constants
import { DATE_TIME_ZONE_FORMAT, USER_TYPES } from '../../../../constants';
import { connect } from 'react-redux';

const FISHING_PERFORMANCE = 'Fishing Performance';
const COMPLIANCE_REPORTS = 'Compliance Reports';
const VIDEOS = 'Videos';
const IMAGES = 'Images';

class TripHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: 0,
      trip: null,
      isLoadingTrip: false,
      videoTrip: null,
      isLoadingVideoTrip: false,
      reportTrip: null,
      isLoadingReportTrip: false,
      isLoadingImageTrip: false,
      imageTrip: null,
      windowWidth: window?.innerWidth,
      reportPdfIndex: null,
      catch: null,
      imagePath: null,
      videoObject: null,

      guestTripPermission: null,
      tabs: []
    };
  }

  componentDidMount() {
    window?.addEventListener('resize', this.updateWindowWidth);

    // If user is a guest, check what kind of access they have to this trip
    if (this.props.isGuest) {
      getGuestTripPermissionFromTripID(this.props.match?.params?.id).then(guestTripPermission => {
        this.setState({ guestTripPermission }, () => {
          const tabs = [];
          if (guestTripPermission.getFishingPerformance()) {
            tabs.push(FISHING_PERFORMANCE);
            this.loadFishingPerformance();
          }
          if (guestTripPermission.getComplianceReports()) {
            tabs.push(COMPLIANCE_REPORTS);
            this.loadReportTrip();
          }
          if (guestTripPermission.getVideos()) {
            tabs.push(VIDEOS);
            this.loadVideoTrip();
          }
          if (guestTripPermission.getImages()) {
            tabs.push(IMAGES);
            this.loadImageTrip();
          }
          this.setState({ tabs });
        });
      }).catch(error => {
        setError(error ?? 'Unable to retrieve guest trip permission.');
      });
    }
    else {
      this.setState({ tabs: [FISHING_PERFORMANCE, COMPLIANCE_REPORTS, VIDEOS, IMAGES] });
      this.loadFishingPerformance();
      this.loadReportTrip();
      this.loadVideoTrip();
      this.loadImageTrip();
    }
  }

  componentWillUnmount() {
    window?.removeEventListener('resize', this.updateWindowWidth);
  }

  updateWindowWidth = () => {
    this.setState({ windowWidth: window?.innerWidth });
  }

  handleTabChange = (_, newValue) => {
    this.setState({ selectedTab: newValue });
  }

  loadFishingPerformance() {
    this.setState({ isLoadingTrip: true });
    const tripID = this.props.match?.params?.id;
    listTripStats(tripID).then(trip => {
      this.setState({ trip });
      if (!this.props.isGuest || this.state.guestTripPermission?.getSteamData()) {
        listTripCatch(tripID).then(fishCatch => {
          this.setState({ catch: fishCatch, isLoadingTrip: false });
        }).catch(error => {
          setError(error);
          this.setState({ isLoadingTrip: false });
        });
      }
      else {
        this.setState({ isLoadingTrip: false });
      }
    }).catch(error => {
      setError(error);
      this.setState({ isLoadingTrip: false });
    });
  }

  loadVideoTrip() {
    this.setState({ isLoadingVideoTrip: true });
    const tripID = this.props.match?.params?.id;
    getVideoTrip(tripID).then(videoTrip => {
      this.setState({ isLoadingVideoTrip: false, videoTrip });
    }).catch(error => {
      setError(error ? error : 'Error: Unable to retrieve videos.');
      this.setState({ isLoadingVideoTrip: false });
    });
  }

  loadReportTrip() {
    this.setState({ isLoadingReportTrip: true });
    const tripID = this.props.match?.params?.id;
    getReportTrip(tripID).then(reportTrip => {
      this.setState({ isLoadingReportTrip: false, reportTrip, reportPdfIndex: 0 });
    }).catch(error => {
      setError(error ? error : 'Error: Unable to retrieve report pdfs.');
      this.setState({ isLoadingReportTrip: false });
    });
  }

  loadImageTrip() {
    this.setState({ isLoadingImageTrip: true });
    const tripID = this.props.match?.params?.id;
    getImageTrip(tripID).then(imageTrip => {
      this.setState({ isLoadingImageTrip: false, imageTrip });
    }).catch(error => {
      setError(error ? error : 'Error: Unable to retrieve images.');
      this.setState({ isLoadingImageTrip: false });
    });
  }

  onReloadIFrame = () => {
    document.getElementById('iframe').src = document.getElementById('iframe').src;
  }

  onGoToImage = (path) => {
    this.handleTabChange(null, this.state.tabs?.indexOf(IMAGES));
    this.setState({ imagePath: path });
  }

  onGoToVideo = (videoObject) => {
    this.handleTabChange(null, this.state.tabs?.indexOf(VIDEOS));
    this.setState({ videoObject });
  }

  render() {
    return (
      <Box sx={{ margin: { xs: '10px 10px 0px 10px', md: '30px 30px 0px 30px' } }}>

        <BackToButton to={this.props.isGuest ? '/history/guest' : '/history'} description='Trip History' />

        {/* Trip and Boat Info */}
        <div>
          <Typography variant='h1' style={{ fontSize: 30 }}><b>Trip:</b> {this.state.trip?.getStartTimestamp()?.setZone(this.state.trip.getTimezone())?.toLocaleString(DATE_TIME_ZONE_FORMAT)} - {this.state.trip?.getStopTimestamp()?.setZone(this.state.trip.getTimezone())?.toLocaleString(DATE_TIME_ZONE_FORMAT)}</Typography>
          <Typography variant='h1' style={{ fontSize: 30 }}><b>Vessel:</b> {this.state.trip?.getBoat()?.getName()}</Typography>
          <Typography variant='h1' style={{ fontSize: 30 }}><b>Trip ID:</b> {this.state.trip?.getID()}</Typography>
        </div>

        {/* Content Tabs */}
        <Paper elevation={3} style={{ marginTop: 30 }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={this.state.selectedTab} onChange={this.handleTabChange} variant='scrollable' scrollButtons='auto'>
              {this.state.tabs.map((tab, index) => (
                <Tab key={index} label={tab} id={'simple-tab-' + index} aria-controls={'simple-tabpanel-' + index} />
              ))}
            </Tabs>
          </Box>

          <Box sx={{ padding: { xs: 1.5, md: 3 } }}>

            {/* Overview */}
            {this.state.selectedTab === this.state.tabs?.indexOf(FISHING_PERFORMANCE) && (
              this.state.isLoadingTrip ? (
                <CircularProgress />
              ) : (
                this.state.trip &&
                <OverviewView
                  trip={this.state.trip}
                  catch={this.state.catch}
                  onGoToImage={!this.props.isGuest || this.state.guestTripPermission?.getImages() ? this.onGoToImage : undefined}
                  showSteam={!this.props.isGuest || this.state.guestTripPermission?.getSteamGpsTrack()}
                  showSets={!this.props.isGuest || this.state.guestTripPermission?.getNonAnalyzedSets() /* SYD TODO */}
                  showHauls={!this.props.isGuest || this.state.guestTripPermission?.getNonAnalyzedHauls()}
                />
              )
            )}

            {/* Report */}
            {this.state.selectedTab === this.state.tabs?.indexOf(COMPLIANCE_REPORTS) &&
            <div>
              {this.state.isLoadingReportTrip ? (
                <CircularProgress />
              ) : (
                this.state.reportTrip?.getReportUrls()?.length > 0 ? (
                  <div>
                    {/* Arrows to switch between reports */}
                    {this.state.reportTrip.getReportUrls().length > 1 && (
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'center', marginBottom: '10px' }}>
                        <Button disabled={this.state.reportPdfIndex === 0} onClick={() => this.setState({ reportPdfIndex: this.state.reportPdfIndex - 1 })} style={{ width: '30px' }}>Prev</Button>
                        <Typography variant='button1' style={{ fontSize: 20, margin: '0px 10px 0px 10px' }}>Report {this.state.reportPdfIndex + 1} / {this.state.reportTrip.getReportUrls().length}</Typography>
                        <Button disabled={this.state.reportPdfIndex === this.state.reportTrip.getReportUrls().length - 1} onClick={() => this.setState({ reportPdfIndex: this.state.reportPdfIndex + 1 })} style={{ width: '30px' }}>Next</Button>
                      </div>
                    )}
                    <object data={this.state.reportTrip?.getReportUrls()[this.state.reportPdfIndex]} type="application/pdf" width='100%' height='1060px'>
                      <p><a href={this.state.reportTrip?.getReportUrls()[this.state.reportPdfIndex]}>Link to PDF</a></p>
                    </object>
                  </div>
                ) : <div>No reports found for this trip.</div>
              )}
            </div>}

            {/* Videos */}
            {this.state.selectedTab === this.state.tabs?.indexOf(VIDEOS) &&
            <div>
              {this.state.isLoadingVideoTrip ? (
                <CircularProgress />
              ) : (
                this.state.videoTrip != null &&
                <VideoGallery trip={this.state.videoTrip} videoObject={this.state.videoObject} />
              )}
            </div>}

            {/* Images */}
            {this.state.selectedTab === this.state.tabs?.indexOf(IMAGES) &&
            <div>
              {this.state.isLoadingImageTrip ? (
                <CircularProgress />
              ) : (
                this.state.imageTrip != null &&
                <ImageGallery
                  trip={this.state.imageTrip}
                  imagePath={this.state.imagePath}
                  onGoToVideo={!this.props.isGuest || this.state.guestTripPermission?.getVideos() ? this.onGoToVideo : undefined}
                />
              )}
            </div>}
          </Box>
        </Paper>
      </Box>
    );
  }
}

TripHistory.propTypes = {
  match: PropTypes.object.isRequired,
  isGuest: PropTypes.bool.isRequired
};

const routeAndWrapperMapStateToProps = state => ({
  isGuest: state.auth.userType === USER_TYPES.GUEST || state.auth.skeletonUserUserType === USER_TYPES.GUEST
});

export default connect(routeAndWrapperMapStateToProps, null)(withRouter(TripHistory));

// HTML Viewer
// this.state.reportTrip?.getHtmlReport() != null ?
//   (<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
//     {/* Interactive PDF */}
//     <div style={{ width: '100%', height: '1060px', overflow: 'hidden' }}>
//       <iframe
//         id='iframe'
//         title='Interactive Report'
//         src={this.state.reportTrip?.getHtmlReport()}
//         style={{
//           width: (210 - 0.08 * (window.innerWidth - 320)) + '%',
//           height: (210 - 0.08 * (window.innerWidth - 320)) + '%',
//           transform: 'scale(' + (1 / (0.01 * (210 - 0.08 * (window.innerWidth - 320)))) + ')',
//           transformOrigin: '0 0'
//         }}>
//       </iframe>
//     </div>

//     {/* Buttons */}
//     <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: 20 }}>
//       <Button
//         style={{ width: 250, marginRight: 15 }}F
//         href={this.state.reportTrip?.getPdfReport()}
//         target='_blank'
//         rel='noreferrer'>Download PDF</Button>
//       <Button style={{ width: 250 }} onClick={this.onReloadIFrame}>Reset View</Button>
//     </div>
//   </div>) : <div>No reports found for this trip.</div>
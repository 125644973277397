import { TripHaul, TripSet } from './';

export default class GuestTripSetHaulPermission {
  static thaw(guestTripSetHaulPermissionObj) {
    if (guestTripSetHaulPermissionObj == null) return null;
    const guestTripSetHaulPermission = new GuestTripSetHaulPermission();
    if (guestTripSetHaulPermissionObj.id != null) guestTripSetHaulPermission.setID(guestTripSetHaulPermissionObj.id);
    if (guestTripSetHaulPermissionObj.setId != null) guestTripSetHaulPermission.setSetID(guestTripSetHaulPermissionObj.setId);
    if (guestTripSetHaulPermissionObj.set != null) guestTripSetHaulPermission.setSet(TripSet.thaw(guestTripSetHaulPermissionObj.set));
    if (guestTripSetHaulPermissionObj.haulId != null) guestTripSetHaulPermission.setHaulID(guestTripSetHaulPermissionObj.haulId);
    if (guestTripSetHaulPermissionObj.haul != null) guestTripSetHaulPermission.setHaul(TripHaul.thaw(guestTripSetHaulPermissionObj.haul));
    if (guestTripSetHaulPermissionObj.secondaryHaulData != null) guestTripSetHaulPermission.setSecondaryHaulData(guestTripSetHaulPermissionObj.secondaryHaulData ? true : false);
    if (guestTripSetHaulPermissionObj.secondarySetData != null) guestTripSetHaulPermission.setSecondarySetData(guestTripSetHaulPermissionObj.secondarySetData ? true : false);
    if (guestTripSetHaulPermissionObj.guestTripPermissionId != null) guestTripSetHaulPermission.setGuestTripPermissionID(guestTripSetHaulPermissionObj.guestTripPermissionId);

    return guestTripSetHaulPermission;
  }

  static thawList(guestTripSetHaulPermissionObjList) {
    if (guestTripSetHaulPermissionObjList == null) return [];
    var guestTripSetHaulPermissions = [];
    for (var i = 0; i < guestTripSetHaulPermissionObjList.length; i++) {
      guestTripSetHaulPermissions.push(GuestTripSetHaulPermission.thaw(guestTripSetHaulPermissionObjList[i]));
    }
    return guestTripSetHaulPermissions;
  }

  static freeze(guestTripSetHaulPermission) {
    var guestTripSetHaulPermissionObj = {};
    if (guestTripSetHaulPermission.getID() != null) guestTripSetHaulPermissionObj.id = guestTripSetHaulPermission.getID();
    if (guestTripSetHaulPermission.getSetID() != null) guestTripSetHaulPermissionObj.setId = guestTripSetHaulPermission.getSetID();
    if (guestTripSetHaulPermission.getSet() != null) guestTripSetHaulPermissionObj.set = TripSet.freeze(guestTripSetHaulPermission.getSet());
    if (guestTripSetHaulPermission.getHaulID() != null) guestTripSetHaulPermissionObj.haulId = guestTripSetHaulPermission.getHaulID();
    if (guestTripSetHaulPermission.getHaul() != null) guestTripSetHaulPermissionObj.haul = TripHaul.freeze(guestTripSetHaulPermission.getHaul());
    if (guestTripSetHaulPermission.getSecondaryHaulData() != null) guestTripSetHaulPermissionObj.secondaryHaulData = guestTripSetHaulPermission.getSecondaryHaulData() ? 1 : 0;
    if (guestTripSetHaulPermission.getSecondarySetData() != null) guestTripSetHaulPermissionObj.secondarySetData = guestTripSetHaulPermission.getSecondarySetData() ? 1 : 0;
    if (guestTripSetHaulPermission.getGuestTripPermissionID() != null) guestTripSetHaulPermissionObj.guestTripPermissionId = guestTripSetHaulPermission.getGuestTripPermissionID();
    return guestTripSetHaulPermissionObj;
  }

  static freezeList(guestTripSetHaulPermissions) {
    if (guestTripSetHaulPermissions == null) return [];
    var guestTripSetHaulPermissionObjList = [];
    for (var i = 0; i < guestTripSetHaulPermissions.length; i++) {
      guestTripSetHaulPermissionObjList.push(GuestTripSetHaulPermission.freeze(guestTripSetHaulPermissions[i]));
    }
    return guestTripSetHaulPermissionObjList;
  }

  constructor() {
    this.setID(null);
    this.setSetID(null);
    this.setSet(null);
    this.setHaulID(null);
    this.setHaul(null);
    this.setSecondaryHaulData(null);
    this.setSecondarySetData(null);
    this.setGuestTripPermissionID(null);
  }

  /* -------------------------
     -------------------------
     -------- SETTERS --------
     -------------------------
     ------------------------- */

  setID(id) {
    this.id = id;
  }

  setSetID(setId) {
    this.setId = setId;
  }

  setSet(set) {
    this.set = set;
  }

  setHaulID(haulID) {
    this.haulID = haulID;
  }

  setHaul(haul) {
    this.haul = haul;
  }

  setSecondaryHaulData(secondaryHaulData) {
    this.secondaryHaulData = secondaryHaulData;
  }

  setSecondarySetData(secondarySetData) {
    this.secondarySetData = secondarySetData;
  }

  setGuestTripPermissionID(guestTripPermissionID) {
    this.guestTripPermissionID = guestTripPermissionID;
  }

  /* -------------------------
     -------------------------
     -------- GETTERS --------
     -------------------------
     ------------------------- */

  getID() {
    return this.id;
  }

  getSetID() {
    return this.setId;
  }

  getSet() {
    return this.set;
  }

  getHaulID() {
    return this.haulID;
  }

  getHaul() {
    return this.haul;
  }

  getSecondaryHaulData() {
    return this.secondaryHaulData;
  }

  getSecondarySetData() {
    return this.secondarySetData;
  }

  getGuestTripPermissionID() {
    return this.guestTripPermissionID;
  }

  /* -------------------------
     -------------------------
     -------- HELPERS --------
     -------------------------
     ------------------------- */
}

import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Container } from '@mui/material';

// Alerts
import { clearErrors, setError, setSuccess } from '../../../../alerts';

// API
import { getGuestTripPermission, updateGuestTripPermission } from '../../../../api/admin';
import { BackToButton, GuestTripPermissionForm } from '../../../../components';

class GuestTripPermission extends Component {
  constructor(props) {
    super(props);

    this.state = {
      guestTripPermission: null,
      isLoading: false,
      redirectToTripPermissions: false
    };
  }

  componentDidMount() {
    getGuestTripPermission(this.props.match?.params?.id).then(guestTripPermission => {
      this.setState({ guestTripPermission });
    }).catch(error => {
      setError(error ? error : 'Error: Unable to retrieve existing permission.');
    });
  }

  componentWillUnmount() {
    clearErrors();
  }

  onSubmit = (permissionObj) => {
    this.setState({ isLoading: true });
    updateGuestTripPermission({ guestTripPermissionId: this.state.guestTripPermission.getID(), ...permissionObj }).then(() => {
      setSuccess('Successfully updated guest permission.');
      this.setState({ isLoading: false, redirectToTripPermissions: true });
    }).catch(error => {
      setError(error ?? 'Error: Unable to update guest permission.');
      this.setState({ isLoading: false });
    });
  }

  render() {
    return this.state.redirectToTripPermissions ? <Redirect to={`/admin/trips/${this.state.guestTripPermission?.getTripID()}/shared`} /> : (
      <Container style={{ marginBottom: 30, marginTop: 20 }}>
        <BackToButton to={`/admin/trips/${this.state.guestTripPermission?.getTripID()}/shared`} description='Shared Trip Data' />
        {this.state.guestTripPermission != null &&
        <GuestTripPermissionForm
          isLoading={this.state.isLoading}
          guestTripPermission={this.state.guestTripPermission}
          onSubmit={this.onSubmit}
          tripId={this.state.guestTripPermission?.getTripID()}
        />}
      </Container>
    );
  }
}

GuestTripPermission.propTypes = {
  match: PropTypes.object.isRequired
};

export default withRouter(GuestTripPermission);

export default class TripStatistics {
  static thaw(tripStatisticsObj) {
    if (tripStatisticsObj == null) return null;
    const tripStatistics = new TripStatistics();
    if (tripStatisticsObj.id != null) tripStatistics.setID(tripStatisticsObj.id);
    if (tripStatisticsObj.elapsedTime != null) tripStatistics.setElapsedTime(tripStatisticsObj.elapsedTime);
    if (tripStatisticsObj.elapsedSteamTime != null) tripStatistics.setElapsedSteamTime(tripStatisticsObj.elapsedSteamTime);
    if (tripStatisticsObj.totalDistance != null) tripStatistics.setTotalDistance(tripStatisticsObj.totalDistance);
    if (tripStatisticsObj.steamDistance != null) tripStatistics.setSteamDistance(tripStatisticsObj.steamDistance);
    if (tripStatisticsObj.averageSpeed != null) tripStatistics.setAverageSpeed(tripStatisticsObj.averageSpeed);
    if (tripStatisticsObj.averageSteamSpeed != null) tripStatistics.setAverageSteamSpeed(tripStatisticsObj.averageSteamSpeed);
    if (tripStatisticsObj.averageSetSpeed != null) tripStatistics.setAverageSetSpeed(tripStatisticsObj.averageSetSpeed);
    if (tripStatisticsObj.averageHaulSpeed != null) tripStatistics.setAverageHaulSpeed(tripStatisticsObj.averageHaulSpeed);
    if (tripStatisticsObj.catchLbsPerProcessingHour != null) tripStatistics.setCatchLbsPerProcessingHour(tripStatisticsObj.catchLbsPerProcessingHour);
    if (tripStatisticsObj.retainedLbsPerDragMinute != null) tripStatistics.setRetainedLbsPerDragMinute(tripStatisticsObj.retainedLbsPerDragMinute);
    if (tripStatisticsObj.discardLbsPerDragMinute != null) tripStatistics.setDiscardLbsPerDragMinute(tripStatisticsObj.discardLbsPerDragMinute);
    if (tripStatisticsObj.retainedLbsPerGear != null) tripStatistics.setRetainedLbsPerGear(tripStatisticsObj.retainedLbsPerGear);
    if (tripStatisticsObj.discardLbsPerGear != null) tripStatistics.setDiscardLbsPerGear(tripStatisticsObj.discardLbsPerGear);
    if (tripStatisticsObj.totalRetainedLbs != null) tripStatistics.setTotalRetainedLbs(tripStatisticsObj.totalRetainedLbs);
    if (tripStatisticsObj.totalDiscardLbs != null) tripStatistics.setTotalDiscardLbs(tripStatisticsObj.totalDiscardLbs);
    if (tripStatisticsObj.catchQuality != null) tripStatistics.setCatchQuality(tripStatisticsObj.catchQuality);
    if (tripStatisticsObj.catchQualityNP != null) tripStatistics.setCatchQualityNP(tripStatisticsObj.catchQualityNP);
    if (tripStatisticsObj.catchLbsPerProcessingHour != null) tripStatistics.setCatchLbsPerProcessingHour(tripStatisticsObj.catchLbsPerProcessingHour);
    if (tripStatisticsObj.tripId != null) tripStatistics.setTripID(tripStatisticsObj.tripId);
    if (tripStatisticsObj.analyzedHaulRatio != null) tripStatistics.setAnalyzedHaulRatio(tripStatisticsObj.analyzedHaulRatio);
    if (tripStatisticsObj.setShiftMin != null) tripStatistics.setSetShiftMin(tripStatisticsObj.setShiftMin);
    if (tripStatisticsObj.setShiftQ1 != null) tripStatistics.setSetShiftQ1(tripStatisticsObj.setShiftQ1);
    if (tripStatisticsObj.setShiftMedian != null) tripStatistics.setSetShiftMedian(tripStatisticsObj.setShiftMedian);
    if (tripStatisticsObj.setShiftQ3 != null) tripStatistics.setSetShiftQ3(tripStatisticsObj.setShiftQ3);
    if (tripStatisticsObj.setShiftMax != null) tripStatistics.setSetShiftMax(tripStatisticsObj.setShiftMax);
    if (tripStatisticsObj.haulShiftMin != null) tripStatistics.setHaulShiftMin(tripStatisticsObj.haulShiftMin);
    if (tripStatisticsObj.haulShiftQ1 != null) tripStatistics.setHaulShiftQ1(tripStatisticsObj.haulShiftQ1);
    if (tripStatisticsObj.haulShiftMedian != null) tripStatistics.setHaulShiftMedian(tripStatisticsObj.haulShiftMedian);
    if (tripStatisticsObj.haulShiftQ3 != null) tripStatistics.setHaulShiftQ3(tripStatisticsObj.haulShiftQ3);
    if (tripStatisticsObj.haulShiftMax != null) tripStatistics.setHaulShiftMax(tripStatisticsObj.haulShiftMax);
    return tripStatistics;
  }

  static thawList(tripStatisticsObjList) {
    if (tripStatisticsObjList == null) return [];
    var tripStatisticses = [];
    for (var i = 0; i < tripStatisticsObjList.length; i++) {
      tripStatisticses.push(TripStatistics.thaw(tripStatisticsObjList[i]));
    }
    return tripStatisticses;
  }

  static freeze(tripStatistics) {
    var tripStatisticsObj = {};
    if (tripStatistics.getID() != null) tripStatisticsObj.id = tripStatistics.getID();
    if (tripStatistics.getElapsedTime() != null) tripStatisticsObj.elapsedTime = tripStatistics.getElapsedTime();
    if (tripStatistics.getElapsedSteamTime() != null) tripStatisticsObj.elapsedSteamTime = tripStatistics.getElapsedSteamTime();
    if (tripStatistics.getTotalDistance() != null) tripStatisticsObj.totalDistance = tripStatistics.getTotalDistance();
    if (tripStatistics.getSteamDistance() != null) tripStatisticsObj.steamDistance = tripStatistics.getSteamDistance();
    if (tripStatistics.getAverageSpeed() != null) tripStatisticsObj.averageSpeed = tripStatistics.getAverageSpeed();
    if (tripStatistics.getAverageSteamSpeed() != null) tripStatisticsObj.averageSteamSpeed = tripStatistics.getAverageSteamSpeed();
    if (tripStatistics.getAverageSetSpeed() != null) tripStatisticsObj.averageSetSpeed = tripStatistics.getAverageSetSpeed();
    if (tripStatistics.getAverageHaulSpeed() != null) tripStatisticsObj.averageHaulSpeed = tripStatistics.getAverageHaulSpeed();
    if (tripStatistics.getRetainedLbsPerDragMinute() != null) tripStatisticsObj.retainedLbsPerDragMinute = tripStatistics.getRetainedLbsPerDragMinute();
    if (tripStatistics.getDiscardLbsPerDragMinute() != null) tripStatisticsObj.discardLbsPerDragMinute = tripStatistics.getDiscardLbsPerDragMinute();
    if (tripStatistics.getRetainedLbsPerGear() != null) tripStatisticsObj.retainedLbsPerGear = tripStatistics.getRetainedLbsPerGear();
    if (tripStatistics.getDiscardLbsPerGear() != null) tripStatisticsObj.discardLbsPerGear = tripStatistics.getDiscardLbsPerGear();
    if (tripStatistics.getTotalRetainedLbs() != null) tripStatisticsObj.totalRetainedLbs = tripStatistics.getTotalRetainedLbs();
    if (tripStatistics.getTotalDiscardLbs() != null) tripStatisticsObj.totalDiscardLbs = tripStatistics.getTotalDiscardLbs();
    if (tripStatistics.getCatchQuality() != null) tripStatisticsObj.catchQuality = tripStatistics.getCatchQuality();
    if (tripStatistics.getCatchQualityNP() != null) tripStatisticsObj.catchQualityNP = tripStatistics.getCatchQualityNP();
    if (tripStatistics.getCatchLbsPerProcessingHour() != null) tripStatisticsObj.catchLbsPerProcessingHour = tripStatistics.getCatchLbsPerProcessingHour();
    if (tripStatistics.getTripID() != null) tripStatisticsObj.tripId = tripStatistics.getTripID();
    if (tripStatistics.getAnalyzedHaulRatio() != null) tripStatisticsObj.analyzedHaulRatio = tripStatistics.getAnalyzedHaulRatio();
    if (tripStatistics.getSetShiftMin() != null) tripStatisticsObj.setShiftMin = tripStatistics.getSetShiftMin();
    if (tripStatistics.getSetShiftQ1() != null) tripStatisticsObj.setShiftQ1 = tripStatistics.getSetShiftQ1();
    if (tripStatistics.getSetShiftMedian() != null) tripStatisticsObj.setShiftMedian = tripStatistics.getSetShiftMedian();
    if (tripStatistics.getSetShiftQ3() != null) tripStatisticsObj.setShiftQ3 = tripStatistics.getSetShiftQ3();
    if (tripStatistics.getSetShiftMax() != null) tripStatisticsObj.setShiftMax = tripStatistics.getSetShiftMax();
    if (tripStatistics.getHaulShiftMin() != null) tripStatisticsObj.haulShiftMin = tripStatistics.getHaulShiftMin();
    if (tripStatistics.getHaulShiftQ1() != null) tripStatisticsObj.haulShiftQ1 = tripStatistics.getHaulShiftQ1();
    if (tripStatistics.getHaulShiftMedian() != null) tripStatisticsObj.haulShiftMedian = tripStatistics.getHaulShiftMedian();
    if (tripStatistics.getHaulShiftQ3() != null) tripStatisticsObj.haulShiftQ3 = tripStatistics.getHaulShiftQ3();
    if (tripStatistics.getHaulShiftMax() != null) tripStatisticsObj.haulShiftMax = tripStatistics.getHaulShiftMax();
    return tripStatisticsObj;
  }

  static freezeList(tripStatisticses) {
    if (tripStatisticses == null) return [];
    var tripStatisticsObjList = [];
    for (var i = 0; i < tripStatisticses.length; i++) {
      tripStatisticsObjList.push(TripStatistics.freeze(tripStatisticses[i]));
    }
    return tripStatisticsObjList;
  }

  constructor() {
    this.setID(null);
    this.setElapsedTime(null);
    this.setElapsedSteamTime(null);
    this.setTotalDistance(null);
    this.setSteamDistance(null);
    this.setAverageSpeed(null);
    this.setAverageSteamSpeed(null);
    this.setAverageSetSpeed(null);
    this.setAverageHaulSpeed(null);
    this.setRetainedLbsPerDragMinute(null);
    this.setDiscardLbsPerDragMinute(null);
    this.setRetainedLbsPerGear(null);
    this.setDiscardLbsPerGear(null);
    this.setTotalRetainedLbs(null);
    this.setTotalDiscardLbs(null);
    this.setCatchQuality(null);
    this.setCatchQualityNP(null);
    this.setCatchLbsPerProcessingHour(null);
    this.setTripID(null);
    this.setAnalyzedHaulRatio(null);
    this.setSetShiftMin(null);
    this.setSetShiftQ1(null);
    this.setSetShiftMedian(null);
    this.setSetShiftQ3(null);
    this.setSetShiftMax(null);
    this.setHaulShiftMin(null);
    this.setHaulShiftQ1(null);
    this.setHaulShiftMedian(null);
    this.setHaulShiftQ3(null);
    this.setHaulShiftMax(null);
  }

  /* -------------------------
     -------------------------
     -------- SETTERS --------
     -------------------------
     ------------------------- */

  setID(id) {
    this.id = id;
  }

  setElapsedTime(elapsedTime) {
    this.elapsedTime = elapsedTime;
  }

  setElapsedSteamTime(elapsedSteamTime) {
    this.elapsedSteamTime = elapsedSteamTime;
  }

  setTotalDistance(totalDistance) {
    this.totalDistance = totalDistance;
  }

  setSteamDistance(steamDistance) {
    this.steamDistance = steamDistance;
  }

  setAverageSpeed(averageSpeed) {
    this.averageSpeed = averageSpeed;
  }

  setAverageSteamSpeed(averageSteamSpeed) {
    this.averageSteamSpeed = averageSteamSpeed;
  }

  setAverageSetSpeed(averageSetSpeed) {
    this.averageSetSpeed = averageSetSpeed;
  }

  setAverageHaulSpeed(averageHaulSpeed) {
    this.averageHaulSpeed = averageHaulSpeed;
  }

  setRetainedLbsPerDragMinute(retainedLbsPerDragMinute) {
    this.retainedLbsPerDragMinute = retainedLbsPerDragMinute;
  }

  setDiscardLbsPerDragMinute(discardLbsPerDragMinute) {
    this.discardLbsPerDragMinute = discardLbsPerDragMinute;
  }

  setRetainedLbsPerGear(retainedLbsPerGear) {
    this.retainedLbsPerGear = retainedLbsPerGear;
  }

  setDiscardLbsPerGear(discardLbsPerGear) {
    this.discardLbsPerGear = discardLbsPerGear;
  }
  setTotalRetainedLbs(totalRetainedLbs) {
    this.totalRetainedLbs = totalRetainedLbs;
  }

  setTotalDiscardLbs(totalDiscardLbs) {
    this.totalDiscardLbs = totalDiscardLbs;
  }

  setTripID(tripID) {
    this.tripID = tripID;
  }

  setCatchQuality(catchQuality) {
    this.catchQuality = catchQuality;
  }

  setCatchQualityNP(catchQualityNP) {
    this.catchQualityNP = catchQualityNP;
  }

  setCatchLbsPerProcessingHour(catchLbsPerProcessingHour) {
    this.catchLbsPerProcessingHour = catchLbsPerProcessingHour;
  }

  setAnalyzedHaulRatio(analyzedHaulRatio) {
    this.analyzedHaulRatio = analyzedHaulRatio;
  }

  setSetShiftMin(setShiftMin) {
    this.setShiftMin = setShiftMin;
  }

  setSetShiftQ1(setShiftQ1) {
    this.setShiftQ1 = setShiftQ1;
  }

  setSetShiftMedian(setShiftMedian) {
    this.setShiftMedian = setShiftMedian;
  }

  setSetShiftQ3(setShiftQ3) {
    this.setShiftQ3 = setShiftQ3;
  }

  setSetShiftMax(setShiftMax) {
    this.setShiftMax = setShiftMax;
  }

  setHaulShiftMin(haulShiftMin) {
    this.haulShiftMin = haulShiftMin;
  }

  setHaulShiftQ1(haulShiftQ1) {
    this.haulShiftQ1 = haulShiftQ1;
  }

  setHaulShiftMedian(haulShiftMedian) {
    this.haulShiftMedian = haulShiftMedian;
  }

  setHaulShiftQ3(haulShiftQ3) {
    this.haulShiftQ3 = haulShiftQ3;
  }

  setHaulShiftMax(haulShiftMax) {
    this.haulShiftMax = haulShiftMax;
  }

  /* -------------------------
     -------------------------
     -------- GETTERS --------
     -------------------------
     ------------------------- */

  getID() {
    return this.id;
  }

  getElapsedTime() {
    return this.elapsedTime;
  }

  getElapsedSteamTime() {
    return this.elapsedSteamTime;
  }

  getTotalDistance() {
    return this.totalDistance;
  }

  getSteamDistance() {
    return this.steamDistance;
  }

  getAverageSpeed() {
    return this.averageSpeed;
  }

  getAverageSteamSpeed() {
    return this.averageSteamSpeed;
  }

  getAverageSetSpeed() {
    return this.averageSetSpeed;
  }

  getAverageHaulSpeed() {
    return this.averageHaulSpeed;
  }

  getRetainedLbsPerDragMinute() {
    return this.retainedLbsPerDragMinute;
  }

  getDiscardLbsPerDragMinute() {
    return this.discardLbsPerDragMinute;
  }

  getRetainedLbsPerGear() {
    return this.retainedLbsPerGear;
  }

  getDiscardLbsPerGear() {
    return this.discardLbsPerGear;
  }

  getTotalRetainedLbs() {
    return this.totalRetainedLbs;
  }

  getTotalDiscardLbs() {
    return this.totalDiscardLbs;
  }

  getTripID() {
    return this.tripID;
  }

  getCatchQuality() {
    return this.catchQuality;
  }

  getCatchQualityNP() {
    return this.catchQualityNP;
  }

  getCatchLbsPerProcessingHour() {
    return this.catchLbsPerProcessingHour;
  }

  getAnalyzedHaulRatio() {
    return this.analyzedHaulRatio;
  }

  getSetShiftMin() {
    return this.setShiftMin;
  }

  getSetShiftQ1() {
    return this.setShiftQ1;
  }

  getSetShiftMedian() {
    return this.setShiftMedian;
  }

  getSetShiftQ3() {
    return this.setShiftQ3;
  }

  getSetShiftMax() {
    return this.setShiftMax;
  }

  getHaulShiftMin() {
    return this.haulShiftMin;
  }

  getHaulShiftQ1() {
    return this.haulShiftQ1;
  }

  getHaulShiftMedian() {
    return this.haulShiftMedian;
  }

  getHaulShiftQ3() {
    return this.haulShiftQ3;
  }

  getHaulShiftMax() {
    return this.haulShiftMax;
  }

  /* -------------------------
     -------------------------
     -------- HELPERS --------
     -------------------------
     ------------------------- */

}

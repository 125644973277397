import { DateTime } from 'luxon';
import FishCatch from './FishCatch';

export default class FishCatchItem {
  static thaw(fishCatchItemObj) {
    if (fishCatchItemObj == null) return null;
    const fishCatchItem = new FishCatchItem();
    if (fishCatchItemObj.id != null) fishCatchItem.setID(fishCatchItemObj.id);
    if (fishCatchItemObj.weight != null) fishCatchItem.setWeight(fishCatchItemObj.weight);
    if (fishCatchItemObj.fishCatchId != null) fishCatchItem.setFishCatchID(fishCatchItemObj.fishCatchId);
    if (fishCatchItemObj.timestamp != null) fishCatchItem.setTimestamp(DateTime.fromISO(fishCatchItemObj.timestamp));
    if (fishCatchItemObj.fishCatch != null) fishCatchItem.setFishCatch(FishCatch.thaw(fishCatchItemObj.fishCatch));
    if (fishCatchItemObj.awsVideoPath != null) fishCatchItem.setAwsVideoPath(fishCatchItemObj.awsVideoPath);
    if (fishCatchItemObj.awsImagePath != null) fishCatchItem.setAwsImagePath(fishCatchItemObj.awsImagePath);
    return fishCatchItem;
  }

  static thawList(fishCatchItemObjList) {
    if (fishCatchItemObjList == null) return [];
    var fishCatchItemes = [];
    for (var i = 0; i < fishCatchItemObjList.length; i++) {
      fishCatchItemes.push(FishCatchItem.thaw(fishCatchItemObjList[i]));
    }
    return fishCatchItemes;
  }

  static freeze(fishCatchItem) {
    var fishCatchItemObj = {};
    if (fishCatchItem.getID() != null) fishCatchItemObj.id = fishCatchItem.getID();
    if (fishCatchItem.getWeight() != null) fishCatchItemObj.weight = fishCatchItem.getWeight();
    if (fishCatchItem.getFishCatchID() != null) fishCatchItemObj.fishCatchId = fishCatchItem.getFishCatchID();
    if (fishCatchItem.getTimestamp() != null) fishCatchItemObj.timestamp = fishCatchItem.getTimestamp().toISO();
    if (fishCatchItem.getFishCatch() != null) fishCatchItemObj.fishCatch = FishCatch.freeze(fishCatchItem.getFishCatch());
    if (fishCatchItem.getAwsVideoPath() != null) fishCatchItemObj.awsVideoPath = fishCatchItem.getAwsVideoPath();
    if (fishCatchItem.getAwsImagePath() != null) fishCatchItemObj.awsImagePath = fishCatchItem.getAwsImagePath();
    return fishCatchItemObj;
  }

  static freezeList(fishCatchItemes) {
    if (fishCatchItemes == null) return [];
    var fishCatchItemObjList = [];
    for (var i = 0; i < fishCatchItemes.length; i++) {
      fishCatchItemObjList.push(FishCatchItem.freeze(fishCatchItemes[i]));
    }
    return fishCatchItemObjList;
  }

  static groupList(fishCatchItemes) {
    if (fishCatchItemes == null) return [];
    var fishCatchItemObjList = [];

    for (let i = 0; i < fishCatchItemes.length; i++) {
      var fishCatchItem = fishCatchItemes[i];

      let index = fishCatchItemObjList.findIndex(elem => elem.getSpeciesName() == fishCatchItem.getSpeciesName());

      if (index != -1) {
        fishCatchItemObjList[index].weight += fishCatchItem.getWeight();
      }
      else {
        fishCatchItemObjList.push(FishCatchItem.thaw(FishCatchItem.freeze(fishCatchItem)));
      }
    }

    return fishCatchItemObjList;
  }

  constructor() {
    this.setID(null);
    this.setWeight(null);
    this.setFishCatchID(null);
    this.setTimestamp(null);
    this.setFishCatch(null);
    this.setAwsVideoPath(null);
    this.setAwsImagePath(null);
  }

  /* -------------------------
     -------------------------
     -------- SETTERS --------
     -------------------------
     ------------------------- */

  setID(id) {
    this.id = id;
  }

  setWeight(weight) {
    this.weight = weight;
  }

  setFishCatchID(fishCatchID) {
    this.fishCatchID = fishCatchID;
  }

  setTimestamp(timestamp) {
    this.timestamp = timestamp;
  }

  setFishCatch(fishCatch) {
    this.fishCatch = fishCatch;
  }

  setAwsVideoPath(awsVideoPath) {
    this.awsVideoPath = awsVideoPath;
  }

  setAwsImagePath(awsImagePath) {
    this.awsImagePath = awsImagePath;
  }

  /* -------------------------
     -------------------------
     -------- GETTERS --------
     -------------------------
     ------------------------- */

  getID() {
    return this.id;
  }

  getWeight() {
    return this.weight;
  }

  getFishCatchID() {
    return this.fishCatchID;
  }

  getTimestamp() {
    return this.timestamp;
  }

  getFishCatch() {
    return this.fishCatch;
  }

  getAwsVideoPath() {
    return this.awsVideoPath;
  }

  getAwsImagePath() {
    return this.awsImagePath;
  }

  /* -------------------------
     -------------------------
     -------- HELPERS --------
     -------------------------
     ------------------------- */

}
